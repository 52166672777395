

a {
  color: #0d75bc;

  &:hover {
    color: #083c5f;
  }
}

//.fade {
//  opacity: 0;
//  transition: opacity .15s linear;
//}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: .35s;
  transition-property: height, visibility;
}




 /*breadcrumbs*/

.breadcrumb {
  padding: 8px 0;
  // margin: 0 -15px 0 0;
  margin: 0;
  list-style: none;
  background-color: #fff;
  border-radius: 4px;
}

.breadcrumb > li {
  display: inline-block;
  white-space: nowrap;
  color: #b7b7b7;

  // text-shadow: 0 1px 0 #ffffff;

  a {
    color: #b7b7b7;
    font-size: 14px;
  }
}

.breadcrumb > li > .divider {
  padding: 0 5px;
  color: #ccc;
}

.breadcrumb > .active {
  color: #999999;
}

.breadcrumb>li+li:before {
  padding: 0 5px;
  color: #ccc;
   content: "/\00a0";
  font-size: 17px;
  direction: ltr;
}

.dropdown-menu .divider {
  border-bottom: none;
}

.navbar .nav > li > a {
  white-space: nowrap;
}

/*
  ==================
  Buttons
  ==================
 */

.btn {
  white-space: nowrap;
  font-family: var(--base-font-family);
}

.btn a:visited {
  color: #fff;
}

.btn-danger {
  background-color: #E19B98;
}

.btn-large {
  padding: 6px 19px;
}

.btn-group {
  margin-left: 5px;
}

.dropdown-menu > li {
  > a.btn-success, > a.btn-warning {
    color: #fff;
  }
}

.dropdown-menu:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #cccccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  right: auto;
}

.dropdown-menu:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  top: -6px;
  right: auto;
}


.btn-group .dropdown-menu > li > a {
  font-size: 15px;
}



/*
  ========================
  Bootstrap Edits
  ========================
*/


.pagination ul > li > a:hover {
  color: #000;
}



.fix-input-add-on {
  padding: 7px 5px !important;
}


/*
  ===========================
  Bootstrap RTL
  ===========================
 */


.list-group-item > .badge, .list-group-item > .label {
  float: left;
}

.navbar .brand,
.navbar .nav,
.navbar .nav > li {
  float: right;
}

.navbar .nav > li  a {
  [class^="icon-"], [class*=" icon-"] {
    margin-top: 8px;
  }
}


.navbar .nav .dropdown-toggle .caret {
  margin-top: 13px;
}


.table:not(.ui) th, .table:not(.ui) td {
  text-align: right;
}

th {
  text-align: right;
}


.nav-tabs > li, .nav-pills > li {
  float: right;
}

.tabs > li, .pills > li {
  float: right;
}

//.pagination ul > li > a,
//.pagination ul > li > span {
//  float: right;
//  padding: 12px 22px;
//}
//
//
//.pagination-large ul > li:first-child > a,
//.pagination-large ul > li:first-child > span {
//  border-bottom-left-radius: 0px;
//  border-top-left-radius: 0px;
//  border-left-width: 0;
//}
//
//.pagination-large ul > li:last-child > a,
//.pagination-large ul > li:last-child > span {
//  border-bottom-right-radius: 0px;
//  border-top-right-radius: 0px;
//  border-left-width: 1px;
//}


.popover {
  text-align: right;
  max-width: 550px;
}

.popover .arrow, .popover .arrow:after {
  left: 0;
}


/*
  ====================
    Dropdown rtl
  ====================
*/

.dropdown-menu {
  float: right;
  left: auto;
  right: 0;

  &.datepicker-dropdown {
    float: left;
    left: 0;
    right: auto;
    z-index: 1100 !important;
  }

  &.left-menu {
    left: 0;
    right: auto;

    &:after {
      left: 10px;
      right: auto;
    }

    &:before {
      left: 9px;
      right: auto;
    }
  }
}

.navbar .nav > li > .dropdown-menu:after {
  right: 10px;
  left: auto;
}

.navbar .nav > li > .dropdown-menu:before {
  right: 9px;
  left: auto;
}

.pull-left > .dropdown-menu {
  left: 0;
  right: auto;
}


/* =============================== */



.panel-title {
  white-space: nowrap;
}

.panel-default > .panel-heading {
  background-color: #f2f7ff;

  .badge {
    margin: 7px 10px 0 0;
  }

  .btn-group {
    margin-top: 8px;
  }

  & > .btn {
    margin-top: 8px;
  }
}






.btn-success {
  color: #ffffff;
  background-color: #2b90d9;
  border-color: #2482c7;
}
.btn-success:focus,
.btn-success.focus {
  color: #ffffff;
  background-color: #2074b1;
  border-color: #103b5b;
}
.btn-success:hover {
  color: #ffffff;
  background-color: #2074b1;
  border-color: #1a6093;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  color: #ffffff;
  background-color: #2074b1;
  border-color: #1a6093;
}
.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
  color: #ffffff;
  background-color: #1a6093;
  border-color: #103b5b;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus {
  background-color: #2b90d9;
  border-color: #2482c7;
}
.btn-success .badge {
  color: #2b90d9;
  background-color: #ffffff;
}



// MISC
//
.dropdown-menu > li > a {
  padding: 7px 20px;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  background-image: none;
}




.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  background-color: #2b90d9;
  border-color: transparent;
  border-radius: 0;
}
