@use 'sass:math';

/*-------------------
     Breakpoints
--------------------*/

$mobileBreakpoint            : 320px;
$tabletBreakpoint            : 768px;
$computerBreakpoint          : 992px;
$largeMonitorBreakpoint      : 1200px;
$widescreenMonitorBreakpoint : 1920px;


/* Responsive */
$largestMobileScreen : ($tabletBreakpoint - 1px);
$largestTabletScreen : ($computerBreakpoint - 1px);
$largestSmallMonitor : ($largeMonitorBreakpoint - 1px);
$largestLargeMonitor : ($widescreenMonitorBreakpoint - 1px);


/* Responsive Widths */
$mobileWidth: 95%;
$tabletWidth: 88%;
$computerWidth: 850px;
$largeMonitorWidth: 900px;
$widescreenMonitorWidth: 950px;


$tinyRatio: 0.4;

$tinyHeaderSize: 1.3em;
$tinyMobileWidth: $mobileWidth;
$tinyTabletWidth: ($tabletWidth * $tinyRatio);
$tinyComputerWidth: ($computerWidth * $tinyRatio);
$tinyLargeMonitorWidth: ($largeMonitorWidth * $tinyRatio);
$tinyWidescreenMonitorWidth: ($widescreenMonitorWidth * $tinyRatio);

$tinyMobileMargin: 0% 0% 0% math.div(-$tinyMobileWidth, 2);
$tinyTabletMargin: 0% 0% 0% math.div(-$tinyTabletWidth, 2);
$tinyComputerMargin: 0px 0px 0px math.div(-$tinyComputerWidth, 2);
$tinyLargeMonitorMargin: 0px 0px 0px math.div(-$tinyLargeMonitorWidth, 2);
$tinyWidescreenMonitorMargin: 0px 0px 0px math.div(-$tinyWidescreenMonitorWidth, 2);


.ui.tiny.modal > .header:not(.ui) {
  font-size: $tinyHeaderSize;
}

/* Tiny Modal Width */
@media only screen and (max-width : $largestMobileScreen) {
  .ui.tiny.modal {
     width: $tinyMobileWidth;
     margin: $tinyMobileMargin;
  }
}
@media only screen and (min-width : $tabletBreakpoint) {
  .ui.tiny.modal {
    width: $tinyTabletWidth;
     margin: $tinyTabletMargin;
  }
}
@media only screen and (min-width : $computerBreakpoint) {
  .ui.tiny.modal {
    width: $tinyComputerWidth;
    margin: $tinyComputerMargin;
  }
}
@media only screen and (min-width : $largeMonitorBreakpoint) {
  .ui.tiny.modal {
    width: $tinyLargeMonitorWidth;
    margin: $tinyLargeMonitorMargin;
  }
}
@media only screen and (min-width : $widescreenMonitorBreakpoint) {
  .ui.tiny.modal {
    width: $tinyWidescreenMonitorWidth;
    margin: $tinyWidescreenMonitorMargin;
  }
}

